import logo from "./assets/logo.png";
import img1 from "./assets/image1.png";
import img2 from "./assets/image2.png";
import img3 from "./assets/image3.png";
import img4 from "./assets/image4.png";
import img5 from "./assets/image5.png";
import img6 from "./assets/image6.png";
import img7 from "./assets/image7.png";
import img8 from "./assets/image8.png";
import img9 from "./assets/image9.png";
import img11 from "./assets/image11.png";
import img12 from "./assets/image12.png"
import { Table } from "react-bootstrap";
function App() {
  return (
    <div class="container">
      <div class="logo">
        <img src={logo} alt="logo" />
        <h1>MoonTap Guide</h1>
      </div>
      <h3 class="version">Edition of 09.08.2024</h3>
      <h2 class="title my-3">Who we are</h2>
      <p class="content">
        Moons Tap is a fun space adventure game where a MoonBag Monkey in a rocket suit tries to reach the moon. Tap to help him overcome space challenges and explore the universe.
      </p>
      <h2 className="title mt-4">How to play </h2>
      <div className="gap-3 mt-2 d-md-flex align-items-start img_box">
        <p className="content">
          Tap on the MoonBag Monkey to earn in-game coins per tap. All your earnings will be displayed on the main screen.
        </p>
        <div>
          <img src={img8} alt="guide-image" />
          <p className="content text-center">Upgrade, Coins, and earns  </p>
        </div>
      </div>
      {/* <p className="content">
        When you level up in your ultimate levels by collecting a specific amount of in-game coins, it results in rewards that increase your energy limits.
      </p> */}
      <Table responsive>
        <thead>
          <tr>
            <th>Level</th>
            <th>Level name</th>
            <th>Points required to achieve
            </th>
            <th>Energy limit (Count)
            </th>
          </tr>
        </thead>
        <tbody>
          {
            [
              { lvl: 1, name: "Rocket Fuel", balance: "1M", energy: 2000, },
              { lvl: 2, name: "Lift-Off", balance: "10M", energy: 2500, },
              { lvl: 3, name: "Mid-Course Corrections", balance: "50M", energy: 3000, },
              { lvl: 4, name: "Descent & Landing", balance: "200M", energy: 3500, },
            ].map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.lvl}</td>
                  <td>{item.name}</td>
                  <td>{item.balance}</td>
                  <td>{item.energy}</td>
                  <td>{item.perTapIncome}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
        <img src={img6} alt="help-guide-image" />
        <img src={img4} alt="help-guide-image" />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Energy Count</h3>
          <ul>
            <li>By default, you have an energy count of 2000, which means you can tap 2000 times. It will take 8 to 9 minutes to refill from 0 to 2000.

            </li>
            <li>It will increase as you progress through the game and reach different ultimate levels
            </li>
          </ul>
        </div>
        <img src={img1} alt="help-guide-image" />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Booster</h3>
          <ul>
            <li>
              You have 6 boosters available per day.
            </li>
            <li>When you exhaust your tapping power and want to continue immediately, you can use Full Energy to bypass the auto-refilling time.
            </li>
            <li>After using boosters to update your energy, you must wait 1 hour before using them again.</li>
          </ul>
        </div>
        <div className="img">
          <img src={img12} alt="help " />
        </div>
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Earn more coin</h3>
          <p className="content">In this game, you'll need to complete activities to earn points:</p>
          <ul>
            <li>Join our Telegram channel - 500 points
            </li>
            <li>Follow us on different media platforms:
              <ul>
                <li>Follow our X account - 500 points
                </li>
                <li>Subscribe to our YouTube channel - 500 points
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <img src={img3} alt="help " />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Daily reward</h3>
          <p className="content">You will receive reward points as follows:</p>
          <ul>
            <li>Day 1: Earn 50 coins</li>
            <li>Day 2: Earn 100 coins</li>
            <li>Day 3: Earn 150 coins</li>
            <li>This will continue increasing until the 10th day, after which it will reset and start again. If you do not claim your daily reward the next day, the cycle will reset and start from the beginning.</li>
            <li>Claiming the daily rewards will credit you with 100 Moonbag tokens.</li>
          </ul>
        </div>
        <img src={img7} alt="help " />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3 >Daily Challenges</h3>
          <ul>
            <li>Tap on the screen and prevent the Moonbag character from flying out of gravity.</li>
            <li>You will get 15 seconds to complete this task.</li>
            <li>Completing successfully will provide you with 500 points and 100 Moonbag tokens.</li>
          </ul>
        </div>
        <img src={img5} alt="help " />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>
            Invite friends
          </h3>
          <ul>
            <li>You can invite your friends through different social media platforms, earning 500 points per invite.</li>
            <li>Alternatively, you can copy the game link and share it with friends.</li>
            <li>You can check the friends list to see who you’ve referred to the app.</li>
          </ul>
        </div>
        <img src={img2} alt="help " />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>AirDrop</h3>
          <ul>
            <li>You can connect your MetaMask wallet to collect Moonsbag tokens.</li>
          </ul>
        </div>
        <img src={img9} alt="help " />
      </div>
      <div className="mt-4 gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>How to Earn Moon Tap Tokens</h3>
          <ul>
            <li>
              <b>Tap Count Levels 1-10:</b> Scoring every 20,000 points will award you with 50 Moonsbag tokens.
            </li>
            <li>
              <b>Tap Count Levels 11-20:</b>
              Scoring every 40,000 points will grant you 50 Moonsbag tokens.
            </li>
            <li>
              You can collect a total of 200 Moonsbag tokens from scores.
            </li>
            <li>
              <b>Booster:</b> Consuming all 6 boosters will award you with 100 Moonsbag tokens.
            </li>
            <li>
              <b>Daily Reward:</b> Claiming daily rewards will credit you with 100 Moonsbag tokens.
            </li>
            <li>
              <b>Daily Challenges:</b> Completing daily challenges will provide you with 500 points and 100 Moonsbag tokens.
            </li>
          </ul>
          <p className="content">
            <b>Note:</b>
          </p>
          <ul>
            <li>You can get up to 500 Moonsbag tokens each day.</li>
            <li>You can earn more Moonsbag tokens through the daily challenge.</li>
            <li>You need to claim your Moonsbag tokens to add them to your wallet.</li>
          </ul>
        </div>
        <img src={img11} alt="help " />
      </div>
    </div>
  );
}

export default App;
